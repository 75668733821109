import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to find the Median algorithms",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:53:44.000Z",
  "url": "/2018/02/how-to-find-the-median-algorithms/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I had a super cool exercise on Hackerank contest, I wouldn`}{`’`}{`t explain the whole exercise but only the part of finding the median of an unsorted listed of numbers.`}</p>
    <p>{`In retrospective there are several ways for calculating this simple value, but for this exercise we had to recalculate it each time when only changing one number each time, and the N was very big, so the regular way couldn`}{`’`}{`t cut it.`}</p>
    <h6>{`example code {#examplecode}`}</h6>
    <pre><code parentName="pre" {...{}}>{`data = [5,1,3,2,9,7...,n]  
`}</code></pre>
    <h6>{`Here is the simple regular way: {#hereisthesimpleregularway}`}</h6>
    <pre><code parentName="pre" {...{}}>{`data.sort()  
length = len(data)

if length%2 == 0:  
    answer = (data[length/2]+data[length/2-1])/2
else:  
    answer = data[math.floor(length/2)]
`}</code></pre>
    <h6>{`Select algorithm {#selectalgorithm}`}</h6>
    <p>{`I wouldn`}{`’`}{`t post the select algorithm code here is it is not relevant here (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Selection_algorithm"
      }}>{`you can read here`}</a>{`)`}</p>
    <p>{`The bottom line is simple checking if even or odd length and the getting the K number and if it is even the k-1 and then do the same as you done above.`}</p>
    <h6>{`The solution {#thesolution}`}</h6>
    <p>{`This solution is not the `}{`“`}{`go to`}{`”`}{` as it is quite specific for our exercise, when you need to recalculate the median several times by changing only one number on big unordered lists.`}</p>
    <p>{`You will run through the list and count each number counts.`}</p>
    <p>{`ie:`}</p>
    <pre><code parentName="pre" {...{}}>{`data = [5,1,3,2,9,7,1,1,3]

//means

counts = {  
    1: 3,
    2: 1,
    3: 2,
    5: 1,
    7: 1,
    9: 1
}
`}</code></pre>
    <p>{`and to calculate the median is simply counting to the middle from the bottom up.`}</p>
    <p>{`ie:`}</p>
    <p>{`1,1,1,2,`}<strong parentName="p">{`3`}</strong>{`,3,5,7,9`}</p>
    <p>{`so now when I add another number or remove one, I simple update my counts objects and recounts to the middle again to find the median.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      